import React from 'react';
import styled from 'styled-components'
import { Link } from "gatsby"
import LogoBlock from "../components/LogoBlock"
import SectionHeading from "../components/SectionHeading"
import Background from "../images/section-background.png"
// import LegalBanner from "../images/legal-banner.jpg"
const PostContentBlock = styled.div`
    margin-left:auto;
    margin-right:auto;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #333;
    width:100%;
    padding:60px 2.5vw 60px 2.5vw;
    overflow:hidden;
    & a{
        text-decoration:none;
        color:rgb(60, 87, 161);
    }
    & blockquote{
        width: 80% ;
        margin: auto;
        border-left: 4px solid #d1a760;
    }
    & blockquote p{
        width: 90%;
    }
    & figcaption{
        opacity: 0.5;
        font-family: Roboto;
        font-size: 18px;
        margin-top:20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: normal;
        text-align: center;
        color: #2e3439;
    }
    & table{
        width: 80%;
        margin: auto;
    }
    & iframe{
        margin:20px auto 20px auto;
        width:80%;
    }
    & .wp-block-columns{
        display: flex;
        width:100%;
    }
    & .wp-block-column{
        display: flex;
        flex-direction:column;
        margin: auto;
    }

    & h1{
        font-family: Montserrat;
        font-size: 50px;
        font-weight: 200;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color:rgba(46,52,57,0.9);
        margin-top:${props => props.top || '0px'};
        margin-bottom:${props => props.bottom || '0.6em'};
        position:relative;
        z-index:10;
    }
    & h2{
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 200;        
        line-height: 1.2;
        width:100%;  
        margin:auto;
        margin-bottom:15px;
        color:rgb(60, 87, 161);
    }
    & h3{
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 200;        
        line-height: 1.2;
        width:100%;  
        margin:auto;
        margin-bottom:1em;
        color:rgb(60, 87, 161);
    }
    & h1 strong{
        font-weight:300;
    }
    & h2 strong{
        font-weight:300;
    }
    & h3 strong{
        font-weight:300;
    }
    & h4 strong{
        font-weight:300;
    }
    & p strong{
        font-weight:500;
    }
    & ul{
        width:90%;  
        margin:auto;
        padding-left:30px;
        margin-bottom:1.45em;
    }
    & .wp-block-image{
        width:auto;  
        margin:3% 0 3% 0;
    }
    & figure{
        margin-top:2em;
        display: flex;
        flex-direction:column;
    }
    & figure.alignright span{
        margin-left:auto;
        margin-right:0px;
    }
    & figure img{
        object-fit:contain;
    }
    .wp-block-separator{
        margin-top: calc(1.45rem - 1px);
    }
    & ol{
        width: 90%;
        margin: auto;
        padding-top: 1%;
        padding-bottom: 10px;
    }
    & ol li{
        margin-left:5%;
    }
    & ol li p{
        width:100%;
        margin:auto;
    }
    & p{
        width:100%;  
        margin-left:auto;
        margin-right:auto;
    }
    & .twitter-tweet{
        margin-left: 10%;
    }
    & .instagram-media{
        margin-left:10% !important;
    }
    & .post-title{
        width:80%;
        font-size:3em;
        color:#000;
        text-align:left;
        line-height:1.23;
        margin-bottom:0.5em;
    }
    & .wp-block-cover{
        margin-bottom:3.5em;
        margin:auto; 
        background-size:cover;
        background-position:50% 50%;
    }
    & .gatsby-image-wrapper{
        margin:auto;
    }
    & .wp-block-column img{
        object-fit: contain !important;
    }
    
    @media screen and (max-width: 991px) {
        font-size:16px;
        & figcaption{
            font-size:16px;
        }
        & .post-title{
            width:90%;
        }
        & h1{
            font-size: 2.6em;
        }
        & table{
            overflow:auto;
        }
        & .wp-block-columns{
           flex-direction:column;
        }
    }
    @media screen and (max-width: 767px) {}
    @media screen and (max-width: 479px) {
        padding:80px 2.5vw 80px 2.5vw;
        & h1{            
            width:100% !important;  
        }
        & h2{
            width:100%;  
        }
        & ul{
            width:100%;  
        }
        & .wp-block-image{ 
            margin:3% 0 3% 0;
        }
        & figure{
            margin-top:2em;
        }
        & ol{
            width:100%;  
            margin:auto;
            padding-top:1%;
        }
        & ol li{
            margin-left:5%;
        }
        & ol li p{
            width:100%;
        }
        & p{
            width:100%;  
        }
        
        & .post-title{
            width:100%;
            margin-bottom:0.5em;
        }       
        & img{
            margin-left:0%;
        }
        & .twitter-tweet{
            margin-left: 0%;
        }
        & .instagram-media{
            margin-left:0% !important;
        }
        & table{
            width: 100%;
            margin: auto;
        }
        & iframe{
            width: 100%;
        }
        & .wp-block-column{
            width:100%;
        }
    }
`;

const PostTitle = styled.h1`
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    margin-top:25px;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    @media screen and (max-width: 991px) {
        margin-top:50px;
    }
    @media screen and (max-width: 479px) {
        font-size: 35px;
        margin-top:0px;
    }
`;
const PageWrap = styled.div`
    display:flex;
    flex-direction:column;
    position:relative;
    // overflow:hidden;
`;
const LogoContainer = styled.div`
    widtg:100%;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    padding: 0 2.5% 0 2.5%;
`;
const BlogWrap = styled.div`
    display:flex;
    flex-direction:column;
    padding: 80px 2.5% 0px 2.5%;
    width:100%;
    height:auto;
    position:relative;
`;

const BackSection = styled.div`
    position:absolute;
    bottom:auto;
    left:0;
    right:0%;
    top:100px;
    height:auto;
    z-index:-1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height:140vh;
    background-image:url(${Background});
    background-size:contain;
    background-position:50% 50%;
    background-repeat: no-repeat;
    opacity:0.8;
    @media screen and (max-width: 991px) {
        
    }
    @media screen and (max-width: 479px) {
      
    }
`;
const Breadcrumb = styled.h2`
    font-family: Montserrat;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    margin:20px 0 0px 0;
    @media screen and (max-width: 991px) {
        margin-bottom:0vh;
    }
    @media screen and (max-width: 479px) {
        margin:40px 0 5vh 0;
    }
`;
const Legal = (props) => {
    return (
        <div>
            <PageWrap>
                <LogoContainer>
                    <LogoBlock></LogoBlock>
                    <Breadcrumb>Legal</Breadcrumb>
                </LogoContainer>
                {/* <BlogWrap>
                    <SectionHeading>Blog & Articles</SectionHeading>
                </BlogWrap>         */}
            </PageWrap>
            <PostContentBlock>
                <div dangerouslySetInnerHTML={{ __html: props.content }} style={{ "width": "100%" }}></div>
                <BackSection></BackSection>
            </PostContentBlock>
        </div>
    );
};

export default Legal;