import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Legal from "../views/Legal.js"


export default function PostPage({ pageContext }) {
  
  const { slug,content,title } = pageContext
  const baseUrl = 'https://whealthventures.com/';
  
  return (   
    <Layout background={"#fff"}>
      <SEO title={title}
          url={baseUrl+slug}
          />
      <Legal content={content} title={title}></Legal>
    </Layout> 
  )
}
